<template>
  <div class="product-card" :class="{ 'product-card--grid': grid, 'product-card--absent': !data.price }">
    <a
      v-if="data.price"
      class="product-card__link"
      @click.prevent="openProductModal"
      href="#"
      :aria-label="data.title"
    ></a>
    <div class="product-card__header" v-if="price.price_without_discount">
      <div class="product-card__promotion">
        <div class="product-card__promotion-wrapper">
          <span class="product-card__percent">-{{ Math.round(discountPercentage) }}%</span>
          <!--          <span class="product-card__date">до 31.12</span>-->
        </div>
      </div>
      <!--      <span class="product-card__header-tag">Товар дня</span>-->
      <!--      <div class="product-card__favorite">-->
      <!--        <RemixIconComponent category="health" name="heart-3-line" />-->
      <!--      </div>-->
      <!--      <div class="product-card__favorite">-->
      <!--        <RemixIconComponent category="health" name="heart-3-line" />-->
      <!--      </div>-->
    </div>
    <div class="product-card__body" :class="{ 'product-card--empty': isEmpty }">
      <div class="product-card__img-wrapper">
        <!--        <span class="product-card__tag">Товар дня</span>-->
        <div class="product-card__img">
          <ImgComponent :img="data.img" width="400" height="400" />
          <!--          <div class="product-card__img-empty">-->
          <!--            <span>нет в наличии</span>-->
          <!--          </div>-->
        </div>
        <div v-if="showCountry" class="product-card__flag">
          <figure v-html="data.country.icon" class="icon" />
        </div>
      </div>
      <div class="product-card__info-block" :class="{ 'product-card__info-block--equalizer': !isSale }">
        <div class="product-card__info-wrapper">
          <div class="product-card__info">
            <span v-if="data.price" class="product-card__description">
              {{ price.value }} ₽ за {{ price.step }}
              {{ data.unit_type_id === 1 ? "гр" : "шт" }}
            </span>
            <!--            <div class="product-card__stars">-->
            <!--              <RemixIconComponent category="system" name="star-fill" />-->
            <!--              <span class="product-card__stars-count">4.4</span>-->
            <!--            </div>-->
          </div>
          <span class="product-card__title">
            {{ data.title }}
          </span>
        </div>
        <div class="product-card__price-wrapper">
          <template v-if="!data.price">
            <div class="product-card__empty">
              <!--              <button class="btn btn&#45;&#45;gray btn&#45;&#45;sm product-card__btn-add-cart">-->
              <!--                <RemixIconComponent category="system" name="add-circle-line" />-->
              <!--                <span>Узнать о наличии</span>-->
              <!--              </button>-->
              <span>Товар закончился</span>
            </div>
          </template>
          <template v-else>
            <div v-if="isSale" class="product-card__price-sale">
              <span class="product-card__price-old" v-if="price.price_without_discount">
                {{ (price.price_without_discount * count) | fixedTwo }} ₽
              </span>
              <span
                class="product-card__price-new"
                :class="{ 'product-card__price--discount': price.price_without_discount }"
              >
                {{ (price.value * count) | fixedTwo }} ₽
              </span>
            </div>
            <span v-else class="product-card__price-default">45,00 ₽</span>
            <ProductCountChangeComponent :data="data" @update:count="updateCount" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductModal from "components/modals/components/ProductModal.vue";
import ImgComponent from "components/ImgComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
import { containsSvg } from "@/utils";

export default {
  name: "CardComponent",
  emits: ["closeAll"],
  components: { ProductCountChangeComponent, ImgComponent, RemixIconComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    grid: Object,
  },
  data() {
    return {
      isEmpty: false,
      count: 1,
    };
  },
  methods: {
    containsSvg,
    updateCount(e) {
      this.count = e === 0 ? 1 : e;
    },
    openProductModal() {
      this.$emit("closeAll");
      this.$store.state._modals.push({
        component: ProductModal,
        options: {
          id: this.data.id,
        },
      });
    },
  },
  computed: {
    price() {
      if (this.data.prices && this.data.prices.length) {
        return this.data.prices[0];
      }
      return this.data.price || {};
    },
    isSale() {
      return true;
    },
    discountPercentage() {
      return this.price.price_without_discount
        ? 100 - (this.price.value * 100) / this.price.price_without_discount
        : null;
    },
    showCountry() {
      return this.data.country && this.containsSvg(this.data.country.icon);
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/maxlines.styl"
.product-card {
  position relative
  padding 24px
  border 1px solid var(--white-2)
  background-color var(--white)
  border-radius 16px
  width 100%
  height auto
  max-height 391px
  display flex
  transition var(--transition)

  &--absent {
    cursor default
  }

  +below(1250px) {
    padding 15px
  }
  +below(480px) {
    padding 10px
  }

  &:hover:not(.product-card--absent) {
    border 1px solid var(--main)
  }

  &__body {
    display flex
    flex-direction column
    width 100%
  }

  &__link {
    absolute top left right bottom
    z-index 1
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    z-index: 1;
    width: auto;

    &-tag {
      display: flex;
      background: #3F3B3B;
      font-size: 0.75rem;
      line-height: 16px;
      font-weight: 600;
      border-radius: 6px;
      padding: 4px;
      color: var(--white);
      width: fit-content;
    }
  }

  &__promotion {
    display flex
    height 25px
  }

  &__promotion-wrapper {
    display inline-flex
    height 100%
    background #F8F8F8
    border-radius 8px
  }

  &__percent {
    display inline-flex
    justify-content center
    align-items center
    background var(--red)
    font-size 0.75rem
    color var(--white)
    line-height 17px
    font-weight 500
    padding 4px
    border-radius 8px
    height 100%
  }

  &__date {
    display inline-flex
    justify-content center
    align-items center
    padding 5px 8px
    font-weight 400
    background #F8F8F8
    font-size 0.750rem
    border-radius 8px
    color var(--gray-900)
  }

  &__favorite {
    position relative
    z-index: 1;
    display flex
    width 28px
    height 28px
    justify-content center
    align-items center
    border 1px solid var(--white-2)
    border-radius 999px
    cursor pointer
    transition var(--transition)

    &:hover {
      border-color var(--main)

      .icon svg path {
        fill var(--main)
      }
    }

    .icon svg {
      width 16px
      height 16px

      path {
        transition var(--transition)
        fill var(--gray-900)
      }
    }
  }

  &__img-wrapper {
    position relative
    width 100%
    height 196px

    ^[0]--absent & {
      opacity .5
    }

    +below(560px) {
      height 140px
    }
  }

  &__tag {
    z-index 1
    display flex
    position absolute
    padding 4px
    top 9px
    background #292929
    font-size 0.75rem
    line-height 16px
    font-weight 600
    border-radius 6px
    color var(--white)
  }

  &__img {
    z-index 0
    position absolute
    top 0
    left 0
    bottom 0
    right 0

    img {
      width 100%
      height 100%
      object-fit contain
    }
  }

  &__img-empty {
    position absolute
    top 0
    width 100%
    height 100%
    display flex
    justify-content center
    align-items center
    font-weight 500
    font-size 0.75rem
    //background var(--white-o8)
    background: rgba(255, 255, 255, 0.8);
    //backdrop-filter: saturate(50%) blur(10px);
    color var(--dark)
  }

  &__flag {
    width 21px
    height 15px
    position absolute
    bottom 3px

    .icon {
      width 21px
      height 15px
    }
  }

  &__info-block {
    display flex
    flex-direction column
    justify-content flex-end
    width 100%
    flex-grow 1
  }

  &__info-wrapper {
    display flex
    flex-direction column
    gap 1px

    ^[0]--absent & {
      opacity .5
    }
  }

  &__info {
    display flex
    justify-content space-between
    align-items center
  }

  &__description {
    font-size 0.75rem
    font-weight 400
    line-height 16px
    color var(--gray-5)
  }

  &__stars {
    display flex
    align-items center
    background #F8F8F8
    padding 4px 8px
    line-height: 16px;
    border-radius 4px
    gap 4px

    .icon {
      width 16px
      height 16px

      svg path {
        fill #F8D749
      }
    }
  }

  &__stars-count {
    font-weight 600
    font-size 0.75rem
    color var(--gray-900)
  }

  &__title {
    display flex
    font-weight 500
    font-size 1rem
    line-height 19px
    color var(--gray-900)
    text-align left
    height 40px
    maxlines(2)
    +below(480px) {
      font-weight: 700;
      font-size: 0.875em
      line-height: 20px;
    }

    span {
      text-align left
    }
  }

  &__price-wrapper {
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap
    gap: 5px
    padding-top 13px
    z-index 1
    margin-top auto
    +below(480px) {
      padding-top 0
    }
  }

  &__price-sale {
    display flex
    flex-direction column
    align-items flex-start
  }

  &__price-default {
    font-weight 500
    font-size 1.125rem
    line-height 24px
    align-self flex-end
  }

  &__empty {
    margin-top 2px
    width 100%
    height 36px
    font-size: 0.875rem
  }

  &__price {
    &-old {
      color var(--gray-400)
      font-size 0.75rem
      line-height 16px
      font-weight 500
      text-decoration line-through
    }

    &-new {
      color var(--gray-900)
      font-weight 500
      font-size 1.125rem
      line-height 22px
    }

    &--discount {
      color var(--red)
    }
  }

  &__cart {
    position relative
    z-index 1
    display flex
    justify-content center
    align-items center
    width 38px
    height 38px
    background var(--green)
    border-radius 16px
    cursor pointer
    transition var(--transition)

    ^[0]--absent & {
      cursor default
    }

    &:active {
      transform scale(0.95)
    }

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--white)
      }
    }
  }

  &__btn-add-cart {
    width 100%
  }

  &--grid {
    height 100%
    & ^[0]--empty {
      .product-card__info-block {
        padding-bottom 18px !important
      }

      .product-card__price-wrapper {
        padding-top 15px !important
      }
    }

    & ^[0]__tag {
      top 7px
    }

    & ^[0]__price-wrapper {
      padding-top: 8px;
    }

    & ^[0]__price-default {
      align-self flex-end
    }

    & ^[0]__price-new {
      line-height 25px
    }

    & ^[0]__body {
      display flex
      flex-direction row
      gap: 16px
    }

    & ^[0]__img-wrapper {
      max-width 140px
      min-width 100px
      height auto
      flex-shrink 2
    }

    & ^[0]__info-wrapper {
      flex-direction column-reverse
      gap 6px
    }

    & ^[0]__flag {
      display flex
      bottom 18px
    }

    & ^[0]__empty {
      margin-top
    }

    & ^[0]__info-block--equalizer {
      padding-bottom 25px

      .product-card__price-default {
        line-height 21px
      }

      .product-card__price-wrapper {
        padding-top 9px
      }
    }
  }
}
</style>
